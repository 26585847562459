import { loadScript, loadStylesheet } from './load';

const primerCssUrl = process.env.PRIMER_CSS_URL as string;
const primerSdkUrl = process.env.PRIMER_SDK_URL as string;

type Primer = typeof import('../Primer');

let clientPromise: Promise<Primer> | undefined;

export async function loadPrimer(): Promise<Primer> {
  if (clientPromise) return clientPromise;

  clientPromise = Promise.all([loadPrimerScript(), loadPrimerCss()]).then(
    ([client]) => client,
  );

  return clientPromise;
}

const loadPrimerCss = () => loadStylesheet(primerCssUrl);

const loadPrimerScript = () =>
  // fake dynamic import:
  //  1. script loads Primer on the window
  //  2. promise is resolved with it
  //  3. immediately deletes it from the window
  loadScript(primerSdkUrl)
    .then(() => {
      const Primer = window.__Primer as Primer; // eslint-disable-line no-underscore-dangle
      delete window.__Primer; // eslint-disable-line no-underscore-dangle
      return Primer;
    })
    .catch((err) => {
      clientPromise = undefined;
      throw err;
    });

declare global {
  interface Window {
    // code in src/Primer.ts, bundled in webpack.primer.js
    __Primer?: Primer;
  }
}
