import { createElementAtHead, listen, querySelector } from './dom';

const sdkLoadingError = `Can't load Primer SDK`;
const cssLoadingError = `${sdkLoadingError}'s Stylesheet`;

export function loadScript(source: string) {
  const type = 'script';
  const srcOrHref = 'src';

  // eslint-disable-next-line no-underscore-dangle
  if (getElement(type, srcOrHref, source) && window.__Primer)
    return Promise.resolve();

  return load(
    type,
    source,
    srcOrHref,
    { async: '', crossorigin: 'anonymous' },
    sdkLoadingError,
  );
}

export function loadStylesheet(source: string) {
  if (Array.from(document.styleSheets).find((s) => s.href === source))
    return Promise.resolve();

  return load('link', source, 'href', { rel: 'stylesheet' }, cssLoadingError);
}

const load = (
  type: 'link' | 'script',
  source: string,
  srcOrHref: 'href' | 'src',
  attributes: Record<string, string>,
  errorMessage: string,
) =>
  new Promise<void>((resolve, reject) => {
    const element =
      getElement(type, srcOrHref, source) ||
      createElementAtHead(type, {
        ...attributes,
        [srcOrHref]: source,
      });

    listen(element, 'load', () => resolve());

    listen(element, 'error', () => {
      element?.remove();
      reject(new Error(errorMessage));
    });
  });

const getElement = (
  type: 'link' | 'script',
  srcOrHref: 'href' | 'src',
  source: string,
) => querySelector(`${type}[${srcOrHref}^="${source}"]`);
