import { Environment } from '../../utils/Environment';
import { AnalyticsEvents } from './AnalyticsEvents';
import { DeviceInfo, getDeviceInfo } from './getDeviceInfo';

const sdkType = 'WEB';
const sdkVersion = Environment.get<string>('PRIMER_SDK_VERSION');

export interface AnalyticsEvent<T> {
  appIdentifier?: string;
  checkoutSessionId?: string;
  clientSessionId?: string;
  createdAt: number;
  customerId?: string;
  device?: DeviceInfo;
  eventType: AnalyticsEvents;
  primerAccountId?: string;
  properties: T;
  sdkType: string;
  sdkVersion: string;
}

export type AnalyticsOptions = Pick<
  AnalyticsEvent<unknown>,
  | 'appIdentifier'
  | 'checkoutSessionId'
  | 'clientSessionId'
  | 'customerId'
  | 'primerAccountId'
>;

export async function createAnalyticsEvent<T>(
  eventType: AnalyticsEvents,
  properties: T,
  options?: AnalyticsOptions,
): Promise<AnalyticsEvent<T>> {
  return {
    ...options,
    createdAt: Date.now(),
    device: await getDeviceInfo(),
    eventType,
    properties,
    sdkVersion,
    sdkType,
  };
}
