export const appendToHead = <N extends Node>(node: N): N =>
  document.head.appendChild(node);

export const createElement = <K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  attributes: Record<string, string>,
): HTMLElementTagNameMap[K] => {
  const element = document.createElement(tagName);
  Object.entries(attributes).forEach(([name, value]) =>
    element.setAttribute(name, value),
  );
  return element;
};

export const createElementAtHead = <K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  attributes: Record<string, string>,
): HTMLElementTagNameMap[K] => appendToHead(createElement(tagName, attributes));

export const listen = <K extends keyof HTMLElementEventMap>(
  element: HTMLElement,
  type: K,
  listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
): void => element.addEventListener(type, listener);

export const querySelector = <E extends HTMLElement>(
  selector: string,
): E | null => document.querySelector(selector);
