export const get = <T>(url: string, headers?: Record<string, string>) =>
  request<T>('get', url, headers);

export const post = <T>(
  url: string,
  payload: Record<string, any>,
  headers?: Record<string, string>,
) => request<T>('post', url, headers, JSON.stringify(payload));

const contentType = {
  'content-type': 'application/json',
};

const request = <T>(
  method: 'get' | 'post',
  url: string,
  headers?: Record<string, string>,
  body?: string,
): Promise<T> =>
  // TODO: remove eslint-disable when eslint's version is updated
  // eslint-disable-next-line compat/compat
  fetch(url, {
    body,
    method,
    headers: {
      ...contentType,
      ...headers,
    },
  }).then((response) => response.json().catch(() => undefined));
