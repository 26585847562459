import { AnalyticsData } from './AnalyticsAction';

export interface CrashEventProperties {
  stacktrace: string;
}

export type MessageSeverity = 'ERROR' | 'DEBUG' | 'WARN' | 'INFO';

export interface MessageEventProperties {
  messageType: string;
  message: string;
  severity?: MessageSeverity;
  diagnosticId?: string;
  [k: string]: string | number | undefined;
}

export enum NetworkCallType {
  REQUEST_START = 'REQUEST_START',
  REQUEST_END = 'REQUEST_END',
}

export type NetworkEventProperties = {
  id: string;
  url: string;
  method: string;
  errorBody?: string;
  responseCode?: number;
  callType: NetworkCallType;
};

export enum TimerType {
  START = 'START',
  END = 'END',
}

export interface TimerEventProperties {
  id: string;
  timerType: TimerType;
}

export interface SdkFunctionEventProperties {
  name: string;
  params?: unknown[];
  module?: string;
}

export type V1EventProperties = {
  data?: AnalyticsData;
  event: string;
};

export type AnalyticsEventProperties =
  | CrashEventProperties
  | MessageEventProperties
  | NetworkEventProperties
  | SdkFunctionEventProperties
  | TimerEventProperties
  | V1EventProperties;
