import {
  Analytics,
  AnalyticsEvent,
  AnalyticsEventProperties,
  AnalyticsEvents,
  AnalyticsOptions,
  createAnalyticsEvent,
} from '../types';

const defaultUrl = process.env.PRIMER_ANALYTICS_API_URL as string;
const MAX_STRING_LENGTH = 1024 * 5;
const sessionStorageKey = (url: string) => `@primer/analytics/${url}`;

export function createHttpAnalyticsProvider({
  url = defaultUrl,
  ...options
}: AnalyticsOptions & { url?: string } = {}): Analytics {
  // optional chaining operators are a Server Side Rendering check
  document?.addEventListener?.('visibilitychange', () => {
    if (document.visibilityState === 'hidden') sendEvents(url);
  });

  const trackEvent = (eventType: AnalyticsEvents) => async (
    properties: AnalyticsEventProperties,
  ) => {
    // Server Side Rendering check
    if (typeof navigator === 'undefined') return;

    const event = await createAnalyticsEvent<AnalyticsEventProperties>(
      eventType,
      properties,
      options,
    );

    // Trim strings properties to a threshold to prevent slowdowns due to sending big
    // analytics messages. The threshold is arbitrary, tune as needed.
    const trimmedEvent = traverseObjectWithFunction(event, trimIfString);

    queueEvent(url, trimmedEvent);
  };

  return {
    crashEvent: trackEvent(AnalyticsEvents.CRASH_EVENT),
    messageEvent: trackEvent(AnalyticsEvents.MESSAGE_EVENT),
    networkCallEvent: trackEvent(AnalyticsEvents.NETWORK_CALL_EVENT),
    sdkFunctionEvent: trackEvent(AnalyticsEvents.SDK_FUNCTION_EVENT),
    timerEvent: trackEvent(AnalyticsEvents.TIMER_EVENT),
    v1Event: trackEvent(AnalyticsEvents.V1_EVENT),
  };
}

function sendEvents(url: string) {
  const key = sessionStorageKey(url);
  const events = sessionStorage.getItem(key);
  sessionStorage.removeItem(key);
  if (events) navigator.sendBeacon(url, events);
}

function queueEvent(
  url: string,
  event: AnalyticsEvent<AnalyticsEventProperties>,
) {
  const key = sessionStorageKey(url);
  sessionStorage.setItem(
    key,
    `[${[sessionStorage.getItem(key)?.slice(1, -1), JSON.stringify(event)]
      .filter(Boolean)
      .join(',')}]`,
  );
}

function traverseObjectWithFunction(
  obj: AnalyticsEvent<AnalyticsEventProperties>,
  fn: <T extends unknown>(value: T) => T | string,
) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) =>
      typeof value === 'object' && value !== null
        ? [key, traverseObjectWithFunction(value, fn)]
        : [key, fn(value)],
    ),
  );
}

function trimIfString<T extends unknown>(value: T) {
  if (typeof value === 'string' && value.length > MAX_STRING_LENGTH) {
    return `${value.substring(0, MAX_STRING_LENGTH)}...`;
  }
  return value;
}
