import BaseAsyncPaymentMethod from '@primer-io/shared-library/async-payment-method/BaseAsyncPaymentMethod';
import BaseClientContext from '../../core/ClientContext';
import { Analytics, SdkFunctionEventProperties } from '../types';

const ignoredTypes = [
  Array,
  BaseAsyncPaymentMethod,
  BaseClientContext,
  Map,
  Set,
];

function recursivelyEnumeratePropertyNames(obj: unknown): string[] {
  const names = new Set<string>();
  const maxIterations = 20;
  let iterations = 0;

  while (iterations++ < maxIterations && obj && obj !== Object.prototype) {
    Object.getOwnPropertyNames(obj).forEach((name) => names.add(name));
    obj = Object.getPrototypeOf(obj);
  }

  return [...names];
}

function createObjectEntryForValue<T>(
  analytics: Analytics,
  key: string,
  value: T,
  ancestor: T,
  module?: string,
): T {
  if (!value || ignoredTypes.some((type) => value instanceof type))
    return value;

  switch (typeof value) {
    case 'function':
      return new Proxy(value, {
        // eslint-disable-next-line @typescript-eslint/ban-types
        apply(target: any, thisArg: any, args: any[] = []) {
          const properties: SdkFunctionEventProperties = {
            name: key.replace(/^bound /, ''),
            params: args,
            module,
          };
          analytics?.sdkFunctionEvent(properties);

          // Proxy the arguments of the functions (usefull for callback handlers)
          const proxiedArgs = args.map((arg, index) => {
            return createObjectEntryForValue(
              analytics,
              `${index}`,
              arg,
              ancestor,
              [module, key, `${index}`].filter(Boolean).join('.'),
            );
          });

          return target.apply(thisArg, proxiedArgs);
        },
      });
    case 'object':
      // do not expand the initial object again to prevent loops
      if (value === ancestor) return value;
      return createMethodsAnalyticsProxy(
        analytics,
        value as never,
        [module, key].filter(Boolean).join('.'),
      );
    default:
      return value;
  }
}

export function createMethodsAnalyticsProxy<T>(
  analytics: Analytics,
  obj: T,
  module?: string,
): T {
  return Object.fromEntries(
    recursivelyEnumeratePropertyNames(obj).map((key) => {
      const value = obj?.[key];
      const entry = createObjectEntryForValue(
        analytics,
        key,
        value,
        value,
        module,
      );
      return [key, entry];
    }),
  ) as T;
}
